import { $fetch, $post, $put, $delete } from '@/services/axios';

export function validate(data) {
  return $post(`company/currency/validate`, data);
}

export function add(data) {
  return $put(`company/currency`, data);
}

export function fetch(data) {
  return $fetch(`company/${data.companyId}/currencies`);
}

export function edit(data) {
  return $post(`company/currency/${data.id}`, data);
}

export function remove(data) {
  return $delete(`company/currency/${data.id}/${data.version}`);
}
