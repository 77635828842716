<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <template #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-2">{{ $t('label.id') }}</th>
            <th class="col-weight-2">{{ $t('label.company') }}</th>
            <th class="col-weight-2">{{ $t('label.currency') }}</th>
            <th v-table-action class="action-remove">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td v-selection:[`market.currency`]="row.currency"></td>
            <td v-rw class="n-button-box">
              <nb-confirm text="remove" @on-ok="doRemove(row)"></nb-confirm>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import { getSelection } from '@/store';
import ModalCompanyCurrency from './ModalCompanyCurrency';
import { fetch, remove } from '@/api/company/company-currency';

export default BaseCompanyView.extend({
  name: 'CompanyCompanyCurrency',

  data() {
    return {
      preload: true,
    };
  },

  mounted() {
    this.companyId = +this.$route.params.companyId;
    let companyName = getSelection('company.company', this.companyId);
    this.breadcrumbItems = [companyName, this.$t(`label.currencies`)];
  },

  methods: {
    doLoad() {
      let companyId = this.companyId;
      return fetch({ companyId });
    },

    doRemove(o) {
      return remove(o);
    },

    openModal(model = { companyId: this.companyId }) {
      this.createModal(ModalCompanyCurrency, { model, props: { subName: 'currency' }, on: this });
    },

    parse(r) {
      this.records = r;
    },
  },
});
</script>
